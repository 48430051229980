import { PRICE_FORMAT } from 'ds4/types';
import { isNil, pickBy, truncate } from 'lodash';
import CurrencyList from 'currency-list';
import { addMinutes } from 'date-fns';

/**
 * Format price
 * @param value: 10 | 20 | ...
 * @param currency: USD | ILS | ...
 * @param format: PRICE_FORMAT.SYMBOL ($10.00, $20.00), PRICE_FORMAT.CURRENCY (USD 10.00, USD 20.00)
 * @returns
 */
export const getPriceFormatted = (
  value: number | null,
  currency: string,
  format = PRICE_FORMAT.CURRENCY
) => {
  if (isNil(value)) return null;

  //TODO: once there will be intl support in OMS integrate it
  try {
    const currencyFormatter = Intl.NumberFormat(getLocale(), {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 2,
    });
    const parts = Number.isFinite(value)
      ? currencyFormatter.formatToParts(value)
      : [];
    const formattedNumber = parts.length
      ? parts
          .filter(part => part.type !== 'currency')
          .reduce((result, part) => result + part.value, '')
      : 'N/A';

    switch (format) {
      case PRICE_FORMAT.SYMBOL:
        return CurrencyList.get(currency || 'USD').symbol + formattedNumber;
      case PRICE_FORMAT.SYMBOL_AND_CURRENCY:
        return `${
          CurrencyList.get(currency || 'USD').symbol
        }${formattedNumber} ${currency}`;
      case PRICE_FORMAT.NO_SYMBOL:
        return formattedNumber;
      default:
        return `${currency} ${formattedNumber}`;
    }
  } catch (e) {
    return 'Invalid currency code';
  }
};

// $-1.23 -> -$1.23
// $-1.23 USD -> -$1.23 USD
// $1.23 USD -> $1.23 USD
export const formatPriceWithNegative = (price: string): string => {
  if (!price) return price;

  const isNegativeIndex = price.indexOf('-');

  if (isNegativeIndex > -1) {
    return `-${price.slice(0, isNegativeIndex)}${price.slice(
      isNegativeIndex + 1
    )}`;
  }

  return price;
};

export const getLocale = () => {
  const currentLocale =
    navigator?.languages?.[0] ?? navigator?.language ?? 'en-US';
  if (typeof currentLocale === 'string' && !currentLocale.includes('-u-nu-')) {
    return `${currentLocale}-u-nu-latn`;
  }
  return currentLocale;
};

export const formatName = ({ firstName, middleName, lastName }: any) =>
  firstName?.concat(
    ' ',
    middleName?.length > 0 ? middleName + ' ' : '',
    lastName
  );

export const formatAddress = ({
  addressLine1,
  city,
  region,
  postalCode,
}: any) => `${addressLine1} ${city}, ${region} ${postalCode}`;

export const formatEllipsisText = ({
  length,
  text,
  omission = '...',
}: {
  text: string;
  length: number;
  omission?: string;
}): string => {
  return truncate(text, {
    length,
    omission,
  });
};

export const formatErrorMessage = ({
  status,
  message,
}: {
  status: number;
  message: string;
}) => {
  if (
    status >= 500 ||
    message?.includes('mongo') ||
    message?.includes('java')
  ) {
    return 'Something went wrong, please try again or contact the support';
  } else {
    return (
      message ||
      'An error occurred while loading the order, kindly check again or contact the support'
    );
  }
};

export const formatDateToUTC = (date: Date): Date | undefined => {
  try {
    return new Date(date.toUTCString());
  } catch (_) {}

  return undefined;
};

export const formatUTCToLocal = (date: Date): string | undefined => {
  try {
    return date?.toLocaleDateString?.('en-US', { timeZone: 'UTC' });
  } catch (_) {}

  return undefined;
};
