export enum OrderItemStatus {
  'CANCELLED' = 'CANCELLED',
  'REFUNDED' = 'REFUNDED',
  'PARTIALLY_CANCELLED' = 'PARTIALLY_CANCELLED',
  'PARTIALLY_REFUNDED' = 'PARTIALLY_REFUNDED',
  'PARTIALLY_CANCELLED_REFUNDED' = 'PARTIALLY_CANCELLED_REFUNDED',
  'VALID' = 'VALID',
  'EXCHANGED' = 'EXCHANGED',
  'PENDING_EXCHANGE' = 'PENDING_EXCHANGE',
  'RETURN' = 'RETURN',
  'PENDING_RETURN' = 'PENDING_RETURN',
  'ORDER_LINE_INVALID_SKU' = 'ORDER_LINE_INVALID_SKU',
  'ORDER_LINE_VALID' = 'ORDER_LINE_VALID',
  'SHIPPED' = 'SHIPPED',
  'PARTIALLY_SHIPPED' = 'PARTIALLY_SHIPPED',
  'ALLOCATED' = 'ALLOCATED',
  'PARTIALLY_ALLOCATED' = 'PARTIALLY_ALLOCATED',
  'DELIVERED' = 'DELIVERED',
  'PARTIALLY_DELIVERED' = 'PARTIALLY_DELIVERED',
}

export enum DateType {
  All = 'all',
  Today = 'today',
  Last7Days = 'last7day',
  LastMonth = 'lastMonth',
  SpecificDates = 'customDate',
}

export interface OrdersNewFiltersType {
  createdAt: DateComplete[];
  updatedAt: DateComplete[];
  statuses: string[];
  channelId: string[];
  orderLineStatus: OrderItemStatus[];
  orderTotal?: {
    min?: number;
    max?: number;
  };
}

export type DateComplete = {
  gte?: Date | null;
  lte?: Date | null;
  type: DateType;
  condition?: string;
  value?: Date;
};

export interface OrdersQueryApiFiltersV3 extends Record<string, any> {
  filters: FilterCondition[];
}
export interface FilterCondition {
  field: string;
  value?: any;
  values?: any;
  condition: string;
}
export interface UserOrdersDataProps {
  page?: number;
  sortBy?: string;
  perPage?: number;
  offset?: number;
  sortDirection?: string;
  filters: OrdersQueryApiFiltersV3;
  modalApplyiedFilters: FilterCondition[];
  groupBy: string;
  alertIdQuery?: string;
  enableUpdateSearchQuery?: any;
}

export interface FiltersProps<T> {
  filters: T;
  onFiltersChange: ({
    key,
    newValue,
  }: {
    key: string;
    newValue: string[];
  }) => void;
}

export interface OrdersFilters {
  [key: string]: string[];
}

export type FilterTag = {
  localKey: string;
  value: string | boolean | number;
  primary: string;
  secondary: string;
  onRemove: () => void;
};

export interface OrdersSearchBarProps extends FiltersProps<OrdersFilters> {
  onSearchTermChange: (searchTerm: string) => void;
  caption: string;
  isLoading?: boolean;
  filterTags?: FilterTag[];
  filterModalData?: any;
}

export interface FilterValueType {
  field: string;
  condition: 'EQ' | 'NEQ' | 'IN' | 'NIN' | 'LT' | 'GT' | 'LTE' | 'GTE';
  value?: any;
  values?: any[];
  type?: DateType;
}

export interface RawFilterType {
  [key: string]: FilterValueType[] | string;
}

export enum ORDER_LISTING_TAB {
  ORDER = 0,
  ALLOCATION,
  SHIPMENT,
  INVOICE,
}

export const tabIndexByQuery = {
  [ORDER_LISTING_TAB.ORDER]: 'orders',
  [ORDER_LISTING_TAB.ALLOCATION]: 'allocations',
  [ORDER_LISTING_TAB.SHIPMENT]: 'shipments',
  [ORDER_LISTING_TAB.INVOICE]: 'invoices',
};
