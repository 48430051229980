import axios, { RawAxiosRequestHeaders, AxiosRequestHeaders } from 'axios';
import getApplicationApiStage from 'lib/utils/getApplicationApiStage';
import { v4 as uuidv4 } from 'uuid';
import { handleRefreshTokenV2 } from '@teamfabric/copilot-utilities';
import { unleash } from 'hooks/useFeatureFlags';
import { parseUnleashVariant } from 'hooks/useFeatureFlag';
import { FEATURE_FLAGS } from 'ds4/config';
import { getTenantId } from 'ds4/utils';

let retry = 0;
const MAX_RETRY = 3;

const getV3Headers = () => {
  return {
    'X-Fabric-Channel-Id': 12,
    'X-Fabric-Request-Id': `copilot-oms-${Date.now()}`,
    'Authorization':
      typeof window !== 'undefined'
        ? 'Bearer ' + sessionStorage.getItem('accessToken')
        : '',
  } as RawAxiosRequestHeaders;
};

interface BaseUrlStrategy {
  (stage: string, type: string): string;
}

const getV3ApiBaseUrl: BaseUrlStrategy = (stage: string, type: string) => {
  return `https://${stage}.oms.fabric.inc/api/v3/${type}`;
};

const ServicesUrls = {
  'location': process.env.OMS_API_V3_LOCATIONS_URL,
  'inventory': process.env.OMS_API_V3_INVENTORY_URL,
  'inventory-networks': process.env.OMS_API_V3_INVENTORY_NETWORK_URL,
  'orders': process.env.OMS_API_V3_ORDERS_URL,
  'allocation': process.env.OMS_API_V3_ALLOCATION_URL,
  'shipment': process.env.OMS_API_V3_SHIPMENTS_URL,
  'shipping': process.env.OMS_API_V3_SHIPPING_URL,
  'configurations': process.env.OMS_API_V3_CONFIGURATIONS_URL,
  'rule-templates': process.env.OMS_API_V3_RULE_TEMPLATES_URL,
  'rule-sets': process.env.OMS_API_V3_RULE_SETS_URL,
  'carts': process.env.CART_V3_BASE_URL,
  'opal': process.env.OPAL_V3_BASE_URL,
  'checkout': process.env.CHECKOUT_V3_BASE_URL,
  'price-lists': process.env.PRICE_LIST_V3_BASE_URL,
  'price-engine': process.env.RTPE_V3_BASE_URL,
  'tax': process.env.INTEGRATIONS_V2_URL,
  'audits': process.env.OMS_API_V3_AUDITS_URL,
  'copilot': process.env.OMS_API_V3_COPILOT_URL,
  'exports': process.env.OMS_API_V3_EXPORT_URL,
  'imports': process.env.OMS_API_V3_IMPORT_URL,
  'fulfillment-logic-methods':
    process.env.OMS_API_V3_FULFILLMENT_LOGIC_METHODS_URL,
  'rule-fact-schemas': process.env.OMS_API_V3_RULE_FACTS_SCHEMA_URL,
  'alerts': process.env.OMS_API_V3_ALERTS_URL,
  'alert-templates': process.env.OMS_API_V3_ALERT_TEMPLATES_URL,
  'incidents': process.env.OMS_API_V3_INCIDENTS_URL,
  'incident-trends': process.env.OMS_API_V3_INCIDENT_TREND_URL,
  'invoices': process.env.OMS_API_V3_INVOICES_URL,
};

const servicesUrlsMap = {
  locations: { id: '/locations', urlKey: 'OMS_API_V3_LOCATIONS_URL' },
  inventories: { id: '/inventories', urlKey: 'OMS_API_V3_INVENTORY_URL' },
  inventoryNetwork: {
    id: '/inventory-networks',
    urlKey: 'OMS_API_V3_INVENTORY_NETWORK_URL',
  },
  audits: { id: '/audits', urlKey: 'OMS_API_V3_AUDITS_URL' },
  orders: { id: '/orders', urlKey: 'OMS_API_V3_ORDERS_URL' },
  copilot: { id: '/copilot', urlKey: 'OMS_API_V3_COPILOT_URL' },
  exports: { id: '/oms-exports', urlKey: 'OMS_API_V3_EXPORT_URL' },
  imports: { id: '/oms-imports', urlKey: 'OMS_API_V3_IMPORT_URL' },
  allocation: { id: '/allocations', urlKey: 'OMS_API_V3_ALLOCATION_URL' },
  shipments: { id: '/shipments', urlKey: 'OMS_API_V3_SHIPMENTS_URL' },
  shippingMethods: {
    id: '/shipping-methods',
    urlKey: 'OMS_API_V3_SHIPPING_URL',
  },
  configurations: {
    id: '/configurations',
    urlKey: 'OMS_API_V3_CONFIGURATIONS_URL',
  },
  ruleSets: { id: '/rule-sets', urlKey: 'OMS_API_V3_RULE_SETS_URL' },
  ruleTemplates: {
    id: '/rule-templates',
    urlKey: 'OMS_API_V3_RULE_TEMPLATES_URL',
  },
  oflMethods: {
    id: '/fulfillment-logic-methods',
    urlKey: 'OMS_API_V3_FULFILLMENT_LOGIC_METHODS_URL',
  },
  ruleFactSchemas: {
    id: '/rule-fact-schemas',
    urlKey: 'OMS_API_V3_RULE_FACTS_SCHEMA_URL',
  },
  alerts: { id: '/alerts', urlKey: 'OMS_API_V3_ALERTS_URL' },
  alertTemplates: {
    id: '/alert-templates',
    urlKey: 'OMS_API_V3_ALERT_TEMPLATES_URL',
  },
  incidents: { id: '/incidents', urlKey: 'OMS_API_V3_INCIDENTS_URL' },
  incidentsTrend: {
    id: '/incident-trends',
    urlKey: 'OMS_API_V3_INCIDENT_TREND_URL',
  },
  invoices: { id: '/invoices', urlKey: 'OMS_API_V3_INVOICES_URL' },
  priceLists: { id: '/price-lists', urlKey: 'PRICE_LIST_V3_BASE_URL' },
  priceEngine: { id: '/price-engine', urlKey: 'RTPE_V3_BASE_URL' },
  carts: { id: '/carts', urlKey: 'CART_V3_BASE_URL' },
  opal: { id: '/', urlKey: 'OPAL_V3_BASE_URL' },
  checkout: { id: '/checkout', urlKey: 'CHECKOUT_V3_BASE_URL' },
  tax: { id: '/tax', urlKey: 'INTEGRATIONS_V2_URL' },
  default: {
    urlKey: 'OMS_API_V3_DEFAULT_BASE_URL',
  },
};

const normalizeURL = URL => (URL.at(-1) === '/' ? URL.slice(0, -1) : URL);

const getAPIBaseURLFromEnvironment: BaseUrlStrategy = (
  stage: string,
  type: string
) => {
  const specificURL = ServicesUrls[type];

  if (specificURL) {
    return normalizeURL(specificURL);
  }

  if (!process.env.OMS_API_V3_DEFAULT_BASE_URL) return null;

  const baseURL = process.env.OMS_API_V3_DEFAULT_BASE_URL;
  return `${normalizeURL(baseURL)}/${type}`;
};

const getAPIBaseFirstFromEnvFallbackToPrevious: BaseUrlStrategy = (
  stage: string,
  type: string
) => {
  return (
    getAPIBaseURLFromEnvironment(stage, type) ?? getV3ApiBaseUrl(stage, type)
  );
};

const getTaxBaseURL: BaseUrlStrategy = (stage: string, type: string) => {
  const url = ServicesUrls[type] ?? process.env.OMS_API_V3_DEFAULT_BASE_URL;
  return normalizeURL(`${url}/${type}`);
};

// Axios Instance Version:V3
const getAxiosInstanceV3 = (
  type: string,
  getHeaders: () => RawAxiosRequestHeaders = getV3Headers,
  baseUrlStrategy: BaseUrlStrategy = getAPIBaseFirstFromEnvFallbackToPrevious
) => {
  const stage = getApplicationApiStage();
  const client = axios.create({
    baseURL: baseUrlStrategy(stage, type),
    responseType: 'json',
    headers: getHeaders(),
  });
  client.interceptors.request.use(request => {
    const token = sessionStorage.getItem('accessToken');
    const tenantId = getTenantId({ axios });

    let authorization = `Bearer ${token}`;

    if (request.headers?.IsNotBearer) {
      authorization = token;
    }

    request.headers = {
      ...request.headers,
      ...getHeaders(),
      'Authorization': authorization,
      'X-Fabric-Tenant-Id': tenantId,
      'X-Fabric-Request-Id': `${
        request.headers['X-Fabric-Request-Id']
      }-${uuidv4()}`,
    } as unknown as AxiosRequestHeaders;
    return request;
  });
  return client;
};

const clientV3 = {
  locations: getAxiosInstanceV3('locations'),
  inventories: getAxiosInstanceV3('inventories'),
  inventoryNetwork: getAxiosInstanceV3('inventory-networks'),
  audits: getAxiosInstanceV3('audits'),
  orders: getAxiosInstanceV3('orders'),
  copilot: getAxiosInstanceV3('copilot'),
  imports: getAxiosInstanceV3('oms-imports'),
  exports: getAxiosInstanceV3('oms-exports'),
  allocation: getAxiosInstanceV3('allocations'),
  shipments: getAxiosInstanceV3('shipments'),
  shippingMethods: getAxiosInstanceV3('shipping-methods'),
  configurations: getAxiosInstanceV3('configurations'),
  ruleSets: getAxiosInstanceV3('rule-sets'),
  ruleTemplates: getAxiosInstanceV3('rule-templates'),
  oflMethods: getAxiosInstanceV3('fulfillment-logic-methods'),
  ruleFactSchemas: getAxiosInstanceV3('rule-fact-schemas'),
  alerts: getAxiosInstanceV3('alerts'),
  alertTemplates: getAxiosInstanceV3('alert-templates'),
  incidents: getAxiosInstanceV3('incidents'),
  incidentsTrend: getAxiosInstanceV3('incident-trends'),
  invoices: getAxiosInstanceV3('invoices'),
  opal: getAxiosInstanceV3('opal'),
  priceLists: getAxiosInstanceV3('price-lists'),
  priceEngine: getAxiosInstanceV3('price-engine'),
  carts: getAxiosInstanceV3('carts'),
  checkout: getAxiosInstanceV3('checkout'),
  tax: getAxiosInstanceV3('tax', getV3Headers, getTaxBaseURL),
};

export const handleErrorResponse = async (error, domain) => {
  if (retry >= MAX_RETRY) {
    retry = 0;
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 || error?.response?.status === 403) {
    try {
      retry = retry + 1;

      const data = await handleRefreshTokenV2();

      if (data === 'success') {
        const accessToken = sessionStorage.getItem('accessToken');
        const tenantId = getTenantId({ axios });

        const request = {
          ...error.config,
          headers: {
            ...error.config.headers,
            'Authorization': accessToken,
            'X-Fabric-Tenant-Id': tenantId,
            'X-Fabric-Request-Id': `${
              error.config.headers['X-Fabric-Request-Id']
            }-${uuidv4()}`,
          },
        };
        return clientV3[domain].request(request);
      } else {
        return Promise.reject(error);
      }
    } catch (error) {
      window.location.href = `${window.location.origin}/auth/v2/login`;
    }
  }
  if (error && error.response && error.response.status === 500) {
    return error.response;
  }
  return Promise.reject(error);
};

Object.keys(clientV3).forEach(domain => {
  clientV3[domain].interceptors.response.use(
    response => {
      return response;
    },
    error => {
      return handleErrorResponse(error, domain);
    }
  );
});

const updateBaseUrlsFromFlags = (flags: any) => {
  Object.keys(clientV3).forEach(domain => {
    const url =
      flags?.[servicesUrlsMap[domain]?.urlKey] ||
      (flags?.[servicesUrlsMap.default.urlKey] &&
        flags?.[servicesUrlsMap.default.urlKey] + servicesUrlsMap[domain]?.id);

    clientV3[domain].defaults.baseURL =
      url ?? clientV3[domain].defaults.baseURL;
  });
};

export const updateV3Client = () => {
  const variant = unleash.getVariant(FEATURE_FLAGS.MT_SERVICE);
  const urls = parseUnleashVariant({ variant });
  const tenantId = getTenantId({});

  const tenantUrls = {
    ...urls?.value?.['default'],
    ...(urls?.value?.[tenantId] || {}),
  };
  updateBaseUrlsFromFlags(tenantUrls); // Update base URLs based on flags
};

export default clientV3;
