import { EnvironmentScope, EnvironmentScopeEnum } from './types';

export const mapStageToEnvironmentScope = (stage: string): EnvironmentScope => {
  switch (stage) {
    case 'prod01':
    case 'prod02':
    case 'live': {
      return EnvironmentScopeEnum.production;
    }
    case 'stg02': {
      return EnvironmentScopeEnum.staging;
    }
    case 'uat':
    case 'sandbox': {
      return EnvironmentScopeEnum.sandbox;
    }
    case 'testing':
    case 'dev02': {
      return EnvironmentScopeEnum.development;
    }
    default: {
      return stage;
    }
  }
};
