import { useNavigate } from 'react-router-dom';
import { DateType } from 'ds4-beta';
import { SEARCH_TYPE } from 'ds4/data';
import { VALIDATION_VALUE_COMPONENT_TYPE } from 'ds4/features/OrdersLanding/data/constants';
import { OrderIdentifier } from 'ds4/types';
import { uniqueId } from 'lodash';
import {
  decodeSearchFilterFromUrl,
  getSearchUrlFromPayload,
} from '@teamfabric/copilot-utilities';
import { VALIDATION_CONDITION_MAPPING } from './filters';
import { useCallback } from 'react';

export const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getOrderDetailsLink = ({
  orderId,
  orderNumber,
  append = '',
}: OrderIdentifier) => {
  const baseUrl = '/orders/manage-orders/edit';
  if (append?.length > 0) {
    return orderNumber
      ? `${baseUrl}/${orderNumber}${append}`
      : `${baseUrl}/orderId/${orderId}${append}`;
  }
  return orderNumber
    ? `${baseUrl}/${orderNumber}`
    : `${baseUrl}/orderId/${orderId}`;
};

export const useNavigateToOrderDetails = () => {
  const navigate = useNavigate();
  const navigateToOrderDetails = useCallback(
    ({ orderId, orderNumber, append = '' }: OrderIdentifier) => {
      return navigate(
        getOrderDetailsLink({
          orderId,
          orderNumber,
          append,
        })
      );
    },
    [navigate]
  );
  return navigateToOrderDetails;
};

export const preFormatSearchText = ({
  keyword,
  type = SEARCH_TYPE.WILDCARD,
}: {
  keyword: string;
  type?: SEARCH_TYPE;
}) => {
  let newValue = keyword || '**';
  if (type === SEARCH_TYPE.WILDCARD) {
    newValue = '*' + keyword + '*';
  } else {
    newValue = keyword;
  }
  return newValue;
};

export const formatSearchValueToBasicFilter = (items, notValidFilter): any => {
  const item = items[0];

  if (!item) return null;
  if (
    item.condition === 'GTE' ||
    item.condition === 'LTE' ||
    item.condition === 'GT' ||
    item.condition === 'LT' ||
    item.condition === 'EQ'
  ) {
    switch (typeof item.value) {
      case 'string':
        const isDate = isNaN(item.value) && !isNaN(Date.parse(item.value));
        if (isDate) {
          const sortDate = items
            ?.sort(
              (a, b) => (new Date(a.value) as any) - (new Date(b.value) as any)
            )
            ?.map(item => ({
              value: new Date(item.value),
              condition: item.condition,
              type: DateType.CustomDates,
            }));
          if (sortDate?.length === 1) {
            if (
              sortDate?.[0]?.condition === 'LTE' ||
              sortDate?.[0]?.condition === 'LT'
            ) {
              sortDate.unshift({
                value: undefined,
                condition: 'GTE',
                type: DateType.CustomDates,
              });
            } else if (
              sortDate?.[0]?.condition === 'GTE' ||
              sortDate?.[0]?.condition === 'GT'
            ) {
              sortDate.push({
                value: undefined,
                condition: 'LTE',
                type: DateType.CustomDates,
              });
            }
          }

          return sortDate;
        }
      case 'number':
        // if (
        //   type === ORDER_LISTING_TAB.ALLOCATION ||
        //   type === ORDER_LISTING_TAB.SHIPMENT ||
        //   type === ORDER_LISTING_TAB.INVOICE
        // ) {
        //   return items?.map(item => ({
        //     value: item.value,
        //     condition: item.condition,
        //   }));
        // }

        if (['LTE', 'LT', 'GTE', 'GT'].includes(items?.[0]?.condition)) {
          return {
            max:
              items[0].condition === 'LTE' || items[0].condition === 'LT'
                ? items?.[0]?.value
                : items?.[1]?.value,
            min:
              items[0].condition === 'GTE' || items[0].condition === 'GT'
                ? items?.[0]?.value
                : items?.[1]?.value,
          };
        } else {
          notValidFilter?.();
        }
    }
  } else {
    if (item.values?.length > 0 && item.condition === 'IN') {
      return items?.reduce((sum, cur) => {
        sum = [...sum, ...cur.values];

        return sum;
      }, []);
    } else if (typeof item.value === 'boolean') {
      return items?.[0]?.value;
    } else {
      notValidFilter?.();
    }
  }
};

export const formatSearchValueToMoreFilter = (items, existFilter): any => {
  const item = items[0];

  if (!item) return null;

  if (
    item.condition === 'GTE' ||
    item.condition === 'LTE' ||
    item.condition === 'GT' ||
    item.condition === 'LT' ||
    item.condition === 'EQ'
  ) {
    switch (typeof item.value) {
      case 'string':
        const isDate = isNaN(item.value) && !isNaN(Date.parse(item.value));
        if (isDate) {
          const sortDate = items
            ?.sort(
              (a, b) => (new Date(a.value) as any) - (new Date(b.value) as any)
            )
            ?.map(item => ({
              value: new Date(item.value),
              condition: item.condition,
              type: DateType.CustomDates,
            }));
          if (sortDate?.length === 1) {
            if (
              sortDate?.[0]?.condition === 'LTE' ||
              sortDate?.[0]?.condition === 'LT'
            ) {
              sortDate.unshift({
                value: undefined,
                condition: 'GTE',
                type: DateType.CustomDates,
              });
            } else if (
              sortDate?.[0]?.condition === 'GTE' ||
              sortDate?.[0]?.condition === 'GT'
            ) {
              sortDate.push({
                value: undefined,
                condition: 'LTE',
                type: DateType.CustomDates,
              });
            }
          }

          return {
            field: existFilter.name,
            validation: '',
            values: sortDate,
            subDocument: null,
            attributeId: existFilter.id,
            type: existFilter.type,
            isTenantAttribute: existFilter?.isTenantAttribute,
          };
        }
      case 'number':
        if (existFilter.type?.toUpperCase() === 'DOUBLE') {
          return {
            field: existFilter.name,
            validation: VALIDATION_CONDITION_MAPPING[items?.[0]?.condition],
            value: [
              {
                id: uniqueId(),
                label: items?.[0]?.value,
              },
            ],
            attributeId: existFilter.id,
            type: existFilter.type,
            isCustom: existFilter.isCustom,
          };
        }
        return {
          field: existFilter.name,
          validation: '',
          value: {
            max: items[0].condition === 'LTE' ? items[0].value : items[1].value,
            min: items[0].condition === 'GTE' ? items[0].value : items[1].value,
          },
          subDocument: null,
          attributeId: existFilter.id,
          type: existFilter.type,
          isTenantAttribute: existFilter?.isTenantAttribute,
        };
    }
  }

  if (existFilter.type === 'STRING') {
    return {
      field: existFilter.name,
      validation:
        VALIDATION_CONDITION_MAPPING[item?.condition] ??
        VALIDATION_VALUE_COMPONENT_TYPE.string[0],
      value: items[0]?.values?.map(value => ({
        id: uniqueId(),
        label:
          value?.startsWith('*') && value?.endsWith('*')
            ? value.slice(1, value.length - 1)
            : value,
      })),
      subDocument: null,
      attributeId: existFilter.id,
      type: existFilter.type,
      isTenantAttribute: existFilter?.isTenantAttribute,
      isCustom: existFilter?.isCustom,
    };
  }

  if (existFilter.type === 'BOOLEAN') {
    return {
      field: existFilter.name,
      validation: '',
      value: items?.[0]?.value?.toString(),
      subDocument: null,
      attributeId: existFilter.id,
      type: existFilter.type,
      isTenantAttribute: existFilter?.isTenantAttribute,
      isCustom: existFilter?.isCustom,
    };
  }

  return {
    field: existFilter.name,
    validation: '',
    value: items?.[0]?.values || items?.[0]?.value,
    subDocument: null,
    attributeId: existFilter.id,
    type: existFilter.type,
    isTenantAttribute: existFilter?.isTenantAttribute,
    isCustom: existFilter?.isCustom,
  };
};

export const getPayloadFromSearchParam = () => {
  (window as any).getSearchUrlFromPayload = getSearchUrlFromPayload;
  return decodeSearchFilterFromUrl(window.location.href);
};

export const flattenKeys = (obj = {}, parentKey = '') => {
  let result = {};

  for (const key in obj) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = { ...result, ...flattenKeys(obj[key], newKey) };
    } else {
      result[newKey] = obj[key];
    }
  }

  return result;
};

export const getTenantId = ({ axios }: { axios?: any }) => {
  const tenantId = window.localStorage.getItem('isLoggedInWithIdV2')
    ? window.sessionStorage.getItem('accountId')
    : window.sessionStorage.getItem('account');

  if (axios && !tenantId) {
    window.location.href = `${window.location.origin}/auth/v2/login`;
    throw new Error(
      new axios.Cancel('Tenant ID is missing. Redirecting to login.')
    );
  }

  return tenantId;
};
