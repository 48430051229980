import { Suspense, useEffect, lazy } from 'react';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import GenericPageLoader from '../components/GenericPageLoader';
import { APP } from 'ds4/data';

const OrdersLanding = lazy(() => import('ds4/features/OrdersLanding'));
const OrderCreation = lazy(() => import('ds4/features/OrderCreation'));
const OrderDetails = lazy(() => import('ds4/features/OrderDetails'));
const ChangeOrderShippingAddress = lazy(
  () => import('ds4/features/ChangeOrderShippingAddress')
);
const OrderAddSkus = lazy(() => import('ds4/features/OrderAddSkus'));
const OrderCancellation = lazy(() => import('ds4/features/OrderCancellation'));
const Appeasement = lazy(() => import('ds4/features/Appeasement'));
const OrderReturn = lazy(() => import('ds4/features/OrderReturn'));

const ActivityLogs = lazy(() => import('ds4/features/ActivityLogs'));

const IncidentsHistory = lazy(() => import('ds4/features/IncidentsHistory'));
const BackorderSettings = lazy(() => import('ds4/features/BackorderSettings'));

const PolicyDetails = lazy(() => import('ds4/features/PolicyDetails'));
const PoliciesLanding = lazy(() => import('ds4/features/PoliciesLanding'));

const AttributeDetails = lazy(() => import('ds4/features/AttributeDetails'));
const AttributesLanding = lazy(() => import('ds4/features/AttributesLanding'));

const ShippingMethodsLanding = lazy(
  () => import('ds4/features/ShippingMethodsLanding')
);
const ShippingMethodDetails = lazy(
  () => import('ds4/features/ShippingMethodDetails')
);

const FulfillmentLanding = lazy(
  () => import('ds4/features/FulfillmentLanding')
);
const FulfillmentDetails = lazy(
  () => import('ds4/features/FulfillmentDetails')
);

const AlertsSettingsLanding = lazy(
  () => import('ds4/features/AlertsSettingsLanding')
);

const AlertSettingsDetails = lazy(
  () => import('ds4/features/AlertSettingsDetails')
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Orders Landing */}
      <Route element={<OrdersLanding />} path={`/${APP}/manage-${APP}`} />
      <Route
        element={<Navigate to={`/${APP}/manage-${APP}`} />}
        path={`/${APP}`}
      />
      <Route
        element={<Navigate to={`/${APP}/manage-${APP}`} />}
        path={`/${APP}/${APP}`}
      />
      {/* Orders Landing */}
      {/* Order Details */}
      <Route
        element={<OrderDetails />}
        path={`/${APP}/manage-${APP}/edit/:orderNumber`}
      />
      <Route
        element={<OrderDetails />}
        path={`/${APP}/manage-${APP}/edit/orderId/:orderId`}
      />
      {/* Order Details */}
      {/* Order Actions */}
      <Route
        element={<OrderCreation />}
        path={`/${APP}/manage-${APP}/create`}
      />
      <Route
        element={<ChangeOrderShippingAddress />}
        path={`/${APP}/manage-${APP}/edit/:orderNumber/change-shipping-address`}
      />
      <Route
        element={<OrderAddSkus />}
        path={`/${APP}/manage-${APP}/edit/:orderNumber/add-skus`}
      />
      <Route
        element={<OrderCancellation />}
        path={`/${APP}/manage-${APP}/edit/:orderNumber/cancel`}
      />
      <Route
        element={<Appeasement />}
        path={`/${APP}/manage-${APP}/edit/:orderNumber/create-appeasement`}
      />
      <Route
        element={<OrderReturn />}
        path={`/${APP}/manage-${APP}/edit/:orderNumber/return`}
      />
      {/* Order Actions */}
      {/* Fulfillment */}
      <Route
        element={<FulfillmentLanding />}
        path={`/${APP}/fulfillment-rules`}
      />
      <Route
        element={<FulfillmentDetails />}
        path={`/${APP}/fulfillment-rules/create`}
      />
      <Route
        element={<FulfillmentDetails />}
        path={`/${APP}/fulfillment-rules/edit/:ruleId`}
      />
      {/* Fulfillment */}
      {/* Activity logs */}
      <Route element={<ActivityLogs />} path={`/${APP}/activity-log`} />
      {/* Activity logs */}
      {/* Attributes */}
      <Route
        element={<AttributesLanding />}
        path={`/${APP}/settings/attributes`}
      />
      <Route
        element={<AttributeDetails />}
        path={`/${APP}/settings/attributes/create`}
      />
      <Route
        element={<AttributeDetails />}
        path={`/${APP}/settings/attributes/edit/:attributeId`}
      />
      {/* Attributes */}
      {/* Shipping methods */}
      <Route
        element={<ShippingMethodsLanding />}
        path={`/${APP}/settings/shipping-methods`}
      />
      <Route
        element={<ShippingMethodDetails />}
        path={`/${APP}/settings/shipping-methods/create`}
      />
      <Route
        element={<ShippingMethodDetails />}
        path={`/${APP}/settings/shipping-methods/edit/:shippingMethodId`}
      />
      {/* Shipping methods */}
      {/* Backorder */}
      <Route
        element={<BackorderSettings />}
        path={`/${APP}/settings/backorder-preorder`}
      />
      {/* Backorder */}
      {/* Policies */}
      <Route element={<PoliciesLanding />} path={`/${APP}/settings/policies`} />
      <Route
        element={<PolicyDetails />}
        path={`/${APP}/settings/policies/create`}
      />
      <Route
        element={<PolicyDetails />}
        path={`/${APP}/settings/policies/edit/:policyType/:policyName`}
      />
      {/* Policies */}
      {/* Alerts */}
      <Route element={<IncidentsHistory />} path={`/${APP}/alerts`} />
      <Route
        element={<AlertsSettingsLanding />}
        path={`/${APP}/settings/alerts`}
      />
      <Route
        element={<AlertSettingsDetails />}
        path={`/${APP}/settings/alerts/create`}
      />
      <Route
        element={<AlertSettingsDetails />}
        path={`/${APP}/settings/alerts/edit/:alertId`}
      />
      {/* Alerts */}
    </>
  )
);

const AppRoutes = () => {
  // const location = useLocation();

  // useEffect(() => {
  //   if (location.pathname !== window.location.pathname) {
  //     navigate(window.location.pathname);
  //   }
  // }, [location]);

  return (
    <Suspense fallback={<GenericPageLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default AppRoutes;
