import { useContext, useMemo } from 'react';
import { FeatureFlagsContext, unleash } from 'hooks/useFeatureFlags';
import { safeJsonParse } from 'ds4/utils/safeJsonParse';
import { IVariant } from 'unleash-proxy-client';

export const parseUnleashVariant = ({ variant }: { variant: IVariant }) => {
  if (!variant) {
    return {};
  }

  const { name, payload } = variant;
  const { type, value } = payload || {};
  if (type === 'json') {
    return { name, value: safeJsonParse(value) };
  }
  return { name, value };
};

const useFeatureFlag = (flagName: string) => {
  const { isFetching } = useContext(FeatureFlagsContext);
  const active = unleash.isEnabled(flagName);

  return useMemo(
    () => ({
      active,
      isFetching,
    }),
    [isFetching, active]
  );
};

export default useFeatureFlag;
