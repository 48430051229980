import { safeJsonParse } from 'ds4/utils/safeJsonParse';

export const FEATURE_FLAGS = Object.freeze({
  ORDER_MORE_FILTERS: 'ORDER_MORE_FILTERS',
  ORDER_CREATE: 'ORDER_CREATE',
  ORDERS_RETURN: 'ORDERS_RETURN',
  ORDERS_ACTIVITY_LOGS_TAB: 'ORDERS_ACTIVITY_LOGS_TAB',
  OFL_ACTIVITY_LOGS_TAB: 'OFL_ACTIVITY_LOGS_TAB',
  CONFIGURATIONS_V3: 'CONFIGURATIONS_V3',
  SEARCH_PAYLOAD_PARAMS: 'SEARCH_PAYLOAD_PARAMS',
  MT_SERVICE: 'MT_SERVICE',
  OFL_METHODS_SOURCE: 'OFL_METHODS_SOURCE',
  RULES_SCHEMA_SOURCE: 'RULES_SCHEMA_SOURCE',
  ORDER_APPEASEMENT: 'ORDER_APPEASEMENT',
  ORDERS_ERROR_HANDLING: 'ORDERS_ERROR_HANDLING',
  COPILOT_RBAC_PERMISSIONS: 'COPILOT_RBAC_PERMISSIONS',
});

export const PIM_API_KEY_MAP = Object.freeze(
  safeJsonParse(process.env.PIM_API_KEY_MAP, {})
);
