import { useEffect, useMemo, useState } from 'react';
import { UnleashClient } from 'unleash-proxy-client';

import { FeatureFlagsProvider, FeatureFlagsContext } from './context';
import { mapStageToEnvironmentScope } from './utils';
import getApplicationStage from 'ds4/utils/getApplicationStage';

export let unleash: UnleashClient = {
  isEnabled: () => false,
  getVariant: () => {},
} as unknown as UnleashClient;

const useFeatureFlags = () => {
  const [isUnleashLoading, setIsUnleashLoading] = useState<boolean>(true);

  useEffect(() => {
    setupUnleash();
    unleash.once('ready', () => {
      setIsUnleashLoading(false);
    });

    return () => unleash.stop();
  }, []);

  return useMemo(() => ({ isFetching: isUnleashLoading }), [isUnleashLoading]);
};

const UNLEASH_DOMAIN_MAP = {
  development: 'dev',
  staging: 'stage',
  sandbox: 'sandbox',
  production: 'prod',
};

export const setupUnleash = () => {
  const userId = sessionStorage.getItem('accountId');

  // unleash client
  unleash = new UnleashClient({
    url: `https://${
      UNLEASH_DOMAIN_MAP[process.env.ENV_DOMAIN] || 'stage'
    }.foundation.feature.fabric.inc/proxy`, //'https://us.app.unleash-hosted.com/uscc1008/',
    clientKey:
      process.env.FEATURE_FLAG_CLIENT_KEY ?? 'copilot-foundations-stage',
    appName: 'copilot_oms',
    environment: mapStageToEnvironmentScope(getApplicationStage()),
  });
  unleash.start();
  unleash.updateContext({ userId });
};

export { FeatureFlagsProvider, FeatureFlagsContext };
export default useFeatureFlags;
