import getApplicationStage from 'ds4/utils/getApplicationStage';

const APPLICATION_API_STAGE_REMAP = {
  sandbox: 'uat01',
  uat: 'uat01',
  live: 'prod01',
} as const;

const getApplicationApiStage = () => {
  const stage = getApplicationStage();
  return APPLICATION_API_STAGE_REMAP[stage] ?? stage;
};

export default getApplicationApiStage;
