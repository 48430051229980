import axios, { RawAxiosRequestHeaders, AxiosRequestHeaders } from 'axios';
import getApplicationStage from 'ds4/utils/getApplicationStage';
import getApplicationApiStage from 'lib/utils/getApplicationApiStage';
import { handleRefreshTokenV2 } from '@teamfabric/copilot-utilities';
import { unleash } from 'hooks/useFeatureFlags';
import { FEATURE_FLAGS, PIM_API_KEY_MAP } from 'ds4/config';
import { parseUnleashVariant } from 'hooks/useFeatureFlag';
import { getTenantId } from 'ds4/utils';
import { v4 as uuidv4 } from 'uuid';

let retry = 0;
const MAX_RETRY = 3;

const getAccountId = () => {
  return window.localStorage.getItem('isLoggedInWithIdV2')
    ? window.sessionStorage.getItem('accountId')
    : window.sessionStorage.getItem('account');
};

const getXSiteContext = () => {
  const tenantId = getTenantId({ axios });

  return JSON.stringify({
    channel: 12,
    account: tenantId,
    date: new Date().toISOString(),
    stage: getApplicationStage(),
    site: 'local',
  });
};

const getV2Headers = () => {
  return {
    'x-site-context': getXSiteContext(),
    'Authorization':
      typeof window !== 'undefined'
        ? sessionStorage.getItem('accessToken')
        : '',
    'X-Fabric-Request-Id': `copilot-oms-${Date.now()}`,
  } as RawAxiosRequestHeaders;
};

const getPimConnectorHeaders = () => {
  return {
    'x-site-context': JSON.stringify({
      channel: 12,
      account: getAccountId(), // logged in user account value
    }),
    'x-api-key': getPimApiKey(),
  } as RawAxiosRequestHeaders;
};

const getPimApiKey = () => {
  const stage = getApplicationStage();
  return (
    process.env.PIM_API_KEY ??
    PIM_API_KEY_MAP[stage] ??
    PIM_API_KEY_MAP['dev02']
  );
};

interface BaseUrlStrategy {
  (stage: string, type: string, urls?: Record<string, string>): string;
}

const servicesUrlsMap = {
  pimconnector: {
    urlKey: 'OMS_API_V2_PIMCONNECTOR_URL',
    id: '',
  },
  order: {
    urlKey: 'OMS_API_V2_ORDER_URL',
    id: '/order',
  },
  configurations: {
    urlKey: 'OMS_API_V2_CONFIGURATIONS_URL',
    id: '/configurations',
  },
  default: {
    urlKey: 'OMS_API_V2_DEFAULT_BASE_URL',
  },
};

const getNewV2ApiBaseUrl: BaseUrlStrategy = (stage: string, type: string) => {
  return `https://${stage}.oms.fabric.inc/api/v2/${type}`;
};

const ServicesUrls = {
  pimconnector: process.env.OMS_API_V2_PIMCONNECTOR_URL,
  order: process.env.OMS_API_V2_ORDER_URL,
  configurations: process.env.OMS_API_V2_CONFIGURATIONS_URL,
};

const normalizeURL = URL => (URL.at(-1) === '/' ? URL.slice(0, -1) : URL);

const getAPIBaseURLFromEnvironment: BaseUrlStrategy = (
  stage: string,
  type: string,
  urls: Record<string, string>
) => {
  const specificURL = urls ? urls[type] : ServicesUrls[type];

  if (specificURL) {
    return normalizeURL(specificURL);
  }

  if (!process.env.OMS_API_V2_DEFAULT_BASE_URL) return null;

  const baseURL = process.env.OMS_API_V2_DEFAULT_BASE_URL;
  return `${normalizeURL(baseURL)}/${type}`;
};

const baseUrlStrategy: BaseUrlStrategy = (stage: string, type: string) => {
  return (
    getAPIBaseURLFromEnvironment(stage, type) ?? getNewV2ApiBaseUrl(stage, type)
  );
};

// Axios Instance Version:V2
const getAxiosInstanceV2 = (
  type: string,
  getHeaders: () => RawAxiosRequestHeaders = getV2Headers
) => {
  const stage = getApplicationApiStage();
  const client = axios.create({
    baseURL: baseUrlStrategy(stage, type),
    responseType: 'json',
    headers: getHeaders(),
  });
  client.interceptors.request.use(request => {
    const token = sessionStorage.getItem('accessToken');
    request.headers = {
      ...request.headers,
      ...getHeaders(),
      'Authorization': token,
      'x-site-context': getXSiteContext(),
      ...(request.headers['X-Fabric-Request-Id']
        ? {
            'X-Fabric-Request-Id': `${
              request.headers['X-Fabric-Request-Id']
            }-${uuidv4()}`,
          }
        : {}),
    } as unknown as AxiosRequestHeaders;
    return request;
  });
  return client;
};

const clientV2 = {
  pimconnector: getAxiosInstanceV2('pimconnector', getPimConnectorHeaders),
  order: getAxiosInstanceV2('order'),
  configurations: getAxiosInstanceV2('configurations'),
};

export const handleErrorResponse = async (error, domain) => {
  if (retry >= MAX_RETRY) {
    retry = 0;
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 || error?.response?.status === 403) {
    try {
      retry = retry + 1;
      const data = await handleRefreshTokenV2();

      if (data === 'success') {
        const accessToken = sessionStorage.getItem('accessToken');

        const request = {
          ...error.config,
          headers: {
            ...error.config.headers,
            'Authorization': accessToken,
            'x-site-context': getXSiteContext(),
            ...(error.config.headers['X-Fabric-Request-Id']
              ? {
                  'X-Fabric-Request-Id': `${
                    error.config.headers['X-Fabric-Request-Id']
                  }-${uuidv4()}`,
                }
              : {}),
          },
        };
        return clientV2[domain].request(request);
      } else {
        return Promise.reject(error);
      }
    } catch (error) {
      window.location.href = `${window.location.origin}/auth/v2/login`;
    }
  }
  if (error && error.response && error.response.status === 500) {
    return error.response;
  }
  return Promise.reject(error);
};

Object.keys(clientV2).forEach(domain => {
  clientV2[domain].interceptors.response.use(
    response => {
      return response;
    },
    error => {
      return handleErrorResponse(error, domain);
    }
  );
});

const updateBaseUrlsFromFlags = (flags: any) => {
  Object.keys(clientV2).forEach(domain => {
    const url =
      flags?.[servicesUrlsMap[domain].urlKey] ||
      (flags?.[servicesUrlsMap.default.urlKey] &&
        flags?.[servicesUrlsMap.default.urlKey] + servicesUrlsMap[domain].id);

    clientV2[domain].defaults.baseURL =
      url ?? clientV2[domain].defaults.baseURL;
  });
};

export const updateV2Client = () => {
  const variant = unleash.getVariant(FEATURE_FLAGS.MT_SERVICE);
  const urls = parseUnleashVariant({ variant });

  const tenantId = getTenantId({});

  const tenantUrls = {
    ...urls?.value?.['default'],
    ...(urls?.value?.[tenantId] || {}),
  };
  updateBaseUrlsFromFlags(tenantUrls); // Update base URLs based on flags
};

export default clientV2;
