import App from 'ds4/pages/app';
import { FeatureFlagsProvider } from 'hooks/useFeatureFlags/context';

const Root = () => {
  return (
    <FeatureFlagsProvider>
      <App />
    </FeatureFlagsProvider>
  );
};
export default Root;
