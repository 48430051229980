import {
  ORDER_LISTING_TAB,
  OrdersQueryApiFiltersV3,
} from 'ds4/features/OrdersLanding/types';
import {
  formatSearchValueToBasicFilter,
  formatSearchValueToMoreFilter,
  getPayloadFromSearchParam,
} from '.';
import { camelCase, cloneDeep, countBy, isDate, isEmpty } from 'lodash';
import { DateFilterValue } from 'ds4-beta';
import { formatDateToUTC } from './formatting';
import { endOfDay, startOfDay } from 'date-fns';

export const isValidAppendSearchContainAsterisk = (search: string) => {
  let isValidAppendSearch = true;

  if (search && search.includes('*')) {
    // check start vs end includes *
    if (
      search.startsWith('*') &&
      search.endsWith('*') &&
      countBy(search)['*'] === 2
    ) {
      isValidAppendSearch = true;
    } else {
      isValidAppendSearch = false;
    }
  }

  return isValidAppendSearch;
};

export const VALIDATION_CONDITION_MAPPING = {
  GTE: { id: 'isGreaterThan', label: 'Is greater than' },
  GT: { id: 'isGreaterThan', label: 'Is greater than' },
  LT: { id: 'isLesserThan', label: 'Is lesser than' },
  LTE: { id: 'isLesserThan', label: 'Is lesser than' },
  EQ: { id: 'isEqualTo', label: 'Is equal to' },
  NIN: { id: 'excludes', label: 'Excludes' },
  IN: { id: 'includes', label: 'Includes' },
};

export const parseSearchParamsToFilter = (
  filters: any,
  moreFiltersAttributes: any,
  type = ORDER_LISTING_TAB.ORDER,
  keySearch = '',
  setBasicFiltersFromSearchUrl
) => {
  // step 1: parse payload from url
  const payloadFromSearchParams = getPayloadFromSearchParam();
  if (!payloadFromSearchParams) return {};

  const clonePayloadSearchParams = cloneDeep(payloadFromSearchParams);

  const forFilters = {};
  const forMoreFilters = [];

  const payloadObject: any = {};
  const payloadObjectOriginal: any = {};

  clonePayloadSearchParams.filters.forEach(item => {
    // [order.{fieldKey}]
    const [_, ...fieldSplit] = item.field.split('.');
    // get {fieldKey}
    const fieldKey = fieldSplit?.join('.');

    if (!payloadObject[fieldKey]) {
      payloadObject[fieldKey] = [{ ...item, field: fieldKey }];
      payloadObjectOriginal[item.field] = [{ ...item, field: item.field }];
    } else {
      payloadObject[fieldKey].push({
        ...item,
        field: fieldKey,
      });
      payloadObjectOriginal[item.field].push({
        ...item,
        field: item.field,
      });
    }
  });

  const search = payloadObject?.[keySearch]?.[0]?.value
    ?.match?.(/([^*])/g)
    ?.join?.('');
  // not effect to remaining key of more filter
  delete payloadObject[keySearch];

  const sort = payloadFromSearchParams?.sort;
  const limit = payloadFromSearchParams?.limit;
  const offset = payloadFromSearchParams?.offset;

  const basicFiltersOriginal = [];

  // step 2: get filter for basic filter
  filters?.forEach((filterKey: string /* all key of basic filter */) => {
    // TODO: api field should be same as field in code
    // currently: statuses (key in code) -> statusCode (api key)
    const key = filterKey === 'statuses' ? 'statusCode' : filterKey;

    // value from search param
    const searchParamValue = payloadObject[key];

    // exist
    if (searchParamValue) {
      forFilters[filterKey] = formatSearchValueToBasicFilter(
        searchParamValue,
        () => {
          if (Array.isArray(searchParamValue)) {
            basicFiltersOriginal.push(
              ...(searchParamValue.map(e => ({ ...e })) || [])
            );
          } else {
            basicFiltersOriginal.push(searchParamValue);
          }
        }
      );

      // delete key for basic filter
      delete payloadObject[key];
    }
  });

  setBasicFiltersFromSearchUrl(basicFiltersOriginal);

  // step 3: get filter for more filter
  Object.keys(payloadObject).forEach(
    (filterKey: string /* all key of more filter */) => {
      // check filterKey exist in attributes
      const existFilter = moreFiltersAttributes?.find(attribute => {
        if (
          type === ORDER_LISTING_TAB.ALLOCATION ||
          type === ORDER_LISTING_TAB.SHIPMENT ||
          type === ORDER_LISTING_TAB.INVOICE
        ) {
          if (!attribute.isCustom) {
            return attribute?.name === filterKey;
          } else {
            return (
              `cartons.items.attributes.${camelCase(
                attribute?.name
              )?.toLowerCase()}` === filterKey?.toLowerCase()
            );
          }
        }
        return attribute?.isTenantAttribute
          ? filterKey?.toLowerCase() ===
              `${
                attribute.subDocument
                  ? `${attribute.subDocument?.toLowerCase()}.`
                  : ''
              }attributes.${camelCase(attribute?.name)}`?.toLowerCase()
          : attribute?.id === filterKey?.replace(/\./g, '_');
      });

      if (existFilter?.id) {
        const result = formatSearchValueToMoreFilter(
          payloadObject[filterKey],
          existFilter
        );
        if (result) {
          forMoreFilters.push(result);
        }
      }
    }
  );

  return {
    // payload
    payloadFromSearchParams,

    // filter
    basicFilter: forFilters,
    moreFilter: forMoreFilters,

    // pagination
    pagination: {
      search,
      sort,
      limit,
      offset,
    },
  };
};

export const parseFilterFromURL = ({
  // key for basic filter
  basicFilterKeys,
  // key for more filter
  moreFiltersAttributes,
  // current tab
  tab = ORDER_LISTING_TAB.ORDER,
  // key for search
  keySearch,
  // set basic filter
  setFilters,
  // set more filter
  setAppliedFilters,
  // set search change
  onSearchChange,
  // set sort direction
  setSortDirection,
  // set sort by
  setSortBy,
  // set pagination
  setPagination,

  // filter that is not valid for basic filter
  setBasicFiltersFromSearchUrl,
}: any) => {
  const { basicFilter, moreFilter, pagination, payloadFromSearchParams } =
    parseSearchParamsToFilter(
      basicFilterKeys,
      moreFiltersAttributes,
      tab,
      keySearch,
      setBasicFiltersFromSearchUrl
    );
  if (!payloadFromSearchParams) return;

  // push in queue to make sure it's last run
  setTimeout(() => {
    setFilters(() => ({ ...basicFilter }));
    setAppliedFilters(() => [...moreFilter]);
    if (pagination.search) {
      onSearchChange(pagination.search);
    }

    if (pagination.sort) {
      const [key, ...fieldKey] = pagination.sort.split('.');

      const sortKey = fieldKey?.join('.');
      const sortDirection = key[0];

      let keySort = 'order';

      switch (tab) {
        case ORDER_LISTING_TAB.ORDER:
          keySort = 'order';
          break;
        case ORDER_LISTING_TAB.ALLOCATION:
          keySort = 'allocation';
          break;
        case ORDER_LISTING_TAB.SHIPMENT:
          keySort = 'shipment';
          break;
        case ORDER_LISTING_TAB.INVOICE:
          keySort = 'invoice';
          break;
      }

      if (key?.slice(1) === keySort) {
        setSortDirection(sortDirection);
        setSortBy(keySort !== 'order' ? `${keySort}.${sortKey}` : sortKey);
      }
    }

    if (pagination.offset) {
      const page = pagination.offset / pagination.limit + 1;
      setPagination(page);
    }
  }, 10);
};

export const parseBasicFilterToApiModel = (
  search,
  searchKey,
  currentFilters,
  prefix = 'order'
): any => {
  const newFilters: OrdersQueryApiFiltersV3 = {
    filters: [],
  };

  if (!isEmpty(search) && searchKey) {
    if (isValidAppendSearchContainAsterisk(search)) {
      newFilters.filters.push({
        field: `${prefix}.${searchKey}`,
        value: search,
        condition: 'EQ',
      });
    }
  }
  Object.keys(currentFilters)?.forEach(key => {
    const filter = currentFilters[key];
    const keyField = key === 'statuses' ? 'statusCode' : key;
    const field = `${prefix}.${keyField}`;
    if (isDate(filter?.[0]?.value) || filter?.[0]?.type === 'customDate') {
      if (filter?.[0]?.value?.toISOString()) {
        newFilters.filters.push({
          field,
          value: filter?.[0]?.value?.toISOString(),
          condition: filter?.[0]?.condition || 'GTE',
        });
      }
    }

    if (isDate(filter?.[1]?.value) || filter?.[1]?.type === 'customDate') {
      if (filter?.[1]?.value?.toISOString()) {
        newFilters.filters.push({
          field,
          value: filter?.[1]?.value?.toISOString(),
          condition: filter?.[1]?.condition || 'LTE',
        });
      }
    }

    if (
      Array.isArray(filter) &&
      filter.length !== 0 &&
      !isDate(filter?.[0]?.value || filter?.[1]?.value)
    ) {
      newFilters.filters.push({
        field,
        values: filter,
        condition: 'IN',
      });
    }

    if (
      !isEmpty(filter) &&
      (filter.min !== undefined || filter.max !== undefined)
    ) {
      if (filter.min !== undefined) {
        newFilters.filters.push({
          field,
          value: filter.min,
          condition: 'GTE',
        });
      }

      if (filter?.max !== undefined) {
        newFilters.filters.push({
          field,
          value: filter?.max,
          condition: 'LTE',
        });
      }
    }
  });

  return newFilters;
};

export const mappingSignSymbolForDate = filters => {
  if (!filters?.[0]?.value && filters?.[1]?.value) {
    return filters?.[1]?.condition === 'LT' ? ' < ' : ' <= ';
  }

  if (!filters?.[1]?.value && filters?.[0]?.value) {
    return filters?.[0]?.condition === 'GT' ? ' > ' : ' >= ';
  }

  return ' - ';
};

export const isDateFilterChange = (selected: DateFilterValue, current: any) => {
  return (
    selected?.dateRange?.from &&
    selected?.dateRange?.to &&
    (formatDateToUTC(startOfDay(selected?.dateRange?.from))?.toISOString?.() !==
      current?.[0]?.value?.toISOString?.() ||
      formatDateToUTC(endOfDay(selected?.dateRange?.to))?.toISOString?.() !==
        current?.[1]?.value?.toISOString?.())
  );
};
