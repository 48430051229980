export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT = 'updatedAt';
export const DEFAULT_ORDER = 'desc';

export const MAX_POLLING_BACKOFF = 300000;
export const POLLING_BACKOFF_EXPONENT = 2;

export enum PollingStatusEnum {
  FINISHED = 'FINISHED',
  FILE_ERROR = 'FILE_ERROR',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  PROCESSING = 'PROCESSING',
  INITIATED = 'INITIATED',
}

export enum ResourcesEnum {
  ORDER = 'ORDER',
  INVOICE = 'INVOICE',
  SHIPMENT = 'SHIPMENT',
  ALLOCATION = 'ALLOCATION',
}

export const BOOLEAN_DROPDOWN_OPTIONS = [
  {
    label: 'Yes',
    id: 'true',
    value: true,
  },
  {
    label: 'No',
    id: 'false',
    value: false,
  },
];

export const DEFAULT_CHANNELS = ['12', '13'];

//BUSINESS, MOBILE, HOME
export const phoneTypes = [
  {
    id: 'mobile',
    value: 'MOBILE',
    label: 'Mobile',
  },
  {
    id: 'business',
    value: 'BUSINESS',
    label: 'Business',
  },
  {
    id: 'home',
    value: 'HOME',
    label: 'Home',
  },
];

export const contactTypeOptions = [
  { id: 'primary', value: 'Primary', label: 'Primary' },
  { id: 'secondary', value: 'Secondary', label: 'Secondary' },
];

export const fulfillmentMethodOptions = [
  { id: 'BOPIS', value: 'BOPIS', label: 'Pickup in store' },
  { id: 'SHIP_TO_ADDRESS', value: 'SHIP_TO_ADDRESS', label: 'Ship to home' },
];

export const DESC_ORDER_V3 = '-';
export const ASC_ORDER_V3 = '+';

export enum SEARCH_TYPE {
  WILDCARD = 'WILDCARD',
  EXACT = 'EXACT',
}

export const APP = 'orders';
