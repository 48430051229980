import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import filters from 'ds4/utils/filtersReducer';
import { createReducer } from 'ds4/store/utils';

const initateStore = envParams => {
  const env = createReducer(envParams, {});

  const reducer = combineReducers({
    filters,
    env,
  });

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(thunk),
      typeof global !== undefined && global.__REDUX_DEVTOOLS_EXTENSION__
        ? global.__REDUX_DEVTOOLS_EXTENSION__()
        : x => x
    ) as any
  );

  return store;
};

export const reducers = combineReducers({
  filters,
  env: {} as any,
});

export default initateStore;
